import DatePicker from 'react-datepicker'; // Import the Date Picker
import 'react-datepicker/dist/react-datepicker.css'; // Import the Date Picker CSS
import React, { useState, useEffect } from 'react';
import {
  Box, Button, TextField, MenuItem, Checkbox, FormControlLabel, Typography, Tooltip,
  Fade, CircularProgress, Snackbar, Alert, Autocomplete, Chip, IconButton, Grid
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useTheme } from '../../contexts/ThemeContext';
import { useLogin } from '../../contexts/LoginContext';
import { useNavigate } from 'react-router-dom';
import PublicShare from '../../utils/PublicShare';
import { useAxios } from '../../contexts/AxiosContext';
import { useMediaQuery } from '@mui/material';
import { Typewriter } from 'react-simple-typewriter';

const Onboarding = ({ }) => {
  const { theme } = useTheme();
  const isMobile = useMediaQuery('(max-width:600px)');
  const { pubProfileLink: contextPubProfileLink, userId, setIsOnboarding, referralCode, logout } = useLogin();
  const [currentPubProfileLink, setCurrentPubProfileLink] = useState(null);
  const [referralEvent, setReferralEvent] = useState(null);
  const [ip_id, setIp_id] = useState('')
  const navigate = useNavigate();
  const axios = useAxios();
  const [step, setStep] = useState(1);
  const [imageData, setImageData] = useState('');
  const [loading, setLoading] = useState(false); // For general loading (API calls)
  const [imageLoading, setImageLoading] = useState(false); // For AI image simulation
  const [alert, setAlert] = useState({ open: false, message: '', severity: '' });
  const [userData, setUserData] = useState({
    fullname: '',
    job: [],
    dob: null,
    logLine: '',
    genre: [],
    format: '',
    title: '',
    privacy: false,
    poster: '',
    favorite: [],
    reasons: {},
    otherprofession: [] // Initialize as an empty array
  });
  const [isUnder18, setIsUnder18] = useState(false); // State to manage under-18 scenario
  const [idea, setIdea] = useState('');
  const [showLogoutText, setShowLogoutText] = useState(false); // State for showing "Logging Out..."
  const [logoutMessage, setLogoutMessage] = useState(""); // State for the logout text sequence
  const [loadingMessage, setLoadingMessage] = useState('');
  const [loadingTimeout, setLoadingTimeout] = useState(null);

  useEffect(() => {
    // Fetch the public profile link
    const fetchPublicLinkId = async () => {
      if (!userId) {
        return;
      }

      try {
        const { data } = await axios.get(`/profile/${userId}/publicProfileLink`);

        if (data && data.pubProfileLink) {
          setCurrentPubProfileLink(data.pubProfileLink);
        } else {
          console.log("No public link ID found in response");
        }
      } catch (error) {
        console.error("Error fetching public link ID:", error);
      }

      try {
        const response = await axios.get(`/onboarding/events-by-referral/${referralCode}`);
        // console.log('Response:', response.data); // Log the response data
        setReferralEvent(response.data)
      } catch (error) {
        if (error.response) {
          // Server responded with a status other than 2xx
          console.error('Error Response:', error.response.status, error.response.data);
        } else if (error.request) {
          // No response received
          console.error('Error Request:', error.request);
        } else {
          // Other errors
          console.error('Error Message:', error.message);
        }
      }
    };

    fetchPublicLinkId();
  }, [userId, axios]);

  const jobOptions = ["Writer", "Director", "DOP", "Producer", "Editor"];

  const otherProfessionOptions = [
    "Accountant", "Academic Advisor", "Academic Researcher", "Actor/Actress", "Advertising Account Manager",
    "Affiliate Marketer", "AI/Machine Learning Engineer", "Animator", "Architect", "Art Conservator", "Art Director", "Aerospace Engineer", "Automotive Engineer", "Auditor", "Barista",
    "Blockchain Developer", "Brand Manager", "Budget Analyst", "Business Analyst", "Business Consultant", "Business Manager", "Catering Manager", "Chef", "Cinematographer", "Cloud Architect",
    "Compliance Officer", "Concierge", "Content Creator (YouTuber, Blogger)", "Content Strategist", "Copywriter", "Corporate Lawyer", "Corporate Treasurer", "Costume Designer", "Creative Director", "Criminal Defense Lawyer",
    "Customer Relationship Manager", "Cybersecurity Specialist", "Data Analyst", "Data Scientist", "Database Administrator", "Dentist", "DevOps Engineer", "Dietitian/Nutritionist", "Digital Marketing Specialist", "Doctor (General Practitioner)",
    "Diversity and Inclusion Officer", "E-Commerce Manager", "Early Childhood Educator", "Electrical Engineer", "E-Learning Specialist", "Employee Engagement Manager", "Engineer", "Environmental Consultant",
    "Environmental Engineer", "Environmental Lawyer", "Event Coordinator", "Event Manager", "Event Planner", "Export Manager", "Fashion Designer", "Financial Analyst", "Financial Planner", "Firefighter",
    "Fleet Manager", "Flight Attendant", "Florist", "Food and Beverage Manager", "Forensic Accountant", "Freight Forwarder", "Full Stack Developer", "Game Designer", "Game Developer", "Graphic Designer",
    "Growth Hacker", "Healthcare Administrator", "Hedge Fund Manager", "Hotel Manager", "HR Analyst", "HR Business Partner", "HR Consultant", "HR Generalist", "Human Resources Manager", "Illustrator",
    "Immigration Lawyer", "Industrial Designer", "Information Security Analyst", "Instructional Designer", "Insurance Underwriter", "Interior Designer", "Investment Banker", "IT Support Specialist", "Judge", "Labor Relations Specialist",
    "Landscape Architect", "Lawyer/Attorney", "Lead Generation Specialist", "Legal Assistant", "Legal Consultant", "Legal Researcher", "Logistics Coordinator", "Logistics Manager", "Marketing Manager", "Market Research Analyst",
    "Mechanical Engineer", "Medical Lab Technician", "Mobile App Developer", "Mortgage Broker", "Music Composer", "Network Engineer", "Nurse", "Occupational Therapist", "Operations Analyst", "Operations Manager",
    "Paramedic", "Pastry Chef", "Pet Groomer", "Pharmacist", "Photographer", "Physical Therapist", "Plumber", "Podcast Producer", "Procurement Manager", "Product Manager (Tech)",
    "Product Marketing Manager", "Project Engineer", "Project Manager", "Psychiatrist", "Psychologist", "Public Relations Specialist", "Radiologist", "Real Estate Agent", "Research Scientist", "Restaurant Manager",
    "Risk Manager", "Robotics Engineer", "Sales Manager", "School Administrator", "School Counselor", "SEO Specialist", "Set Designer", "Social Media Manager", "Software Developer", "Software Engineer",
    "Solutions Architect", "Sound Engineer", "Special Education Teacher", "Speech-Language Pathologist", "Structural Engineer", "Supply Chain Manager", "Surgeon", "Surveyor", "Systems Administrator", "Tax Advisor",
    "Teacher", "Technical Writer", "Technology & Software", "Tour Guide", "Training and Development Manager", "UX/UI Designer", "Venture Capitalist", "Veterinarian", "Video Editor", "Voiceover Artist",
    "Warehouse Manager", "Web Developer"
  ];

  const genreOptions = [
    "Action", "Adventure", "Animated", "Anthology", "Apocalyptic", "Biography", "Buddy", "Comedy", "Coming of Age", "Crime",
    "Cyberpunk", "Dance", "Dark Comedy", "Dark Fantasy", "Detective", "Documentary", "Drama", "Dystopian", "Epic", "Erotica",
    "Experimental", "Family", "Fantasy", "Film Noir", "Found Footage", "Heist", "Historical", "Horror", "Legal", "Martial Arts",
    "Mockumentary", "Musical", "Mystery", "Nature", "Neo-Noir", "Paranormal", "Period Drama", "Political", "Post-Apocalyptic", "Psychological",
    "Psychological Thriller", "Reality", "Road Movie", "Romance", "Satire", "Science Fiction", "Sports", "Spy", "Superhero", "Thriller",
    "War", "Western"
  ];
  const favoriteOptions = ['Inception', 'The Godfather', 'Breaking Bad', 'Interstellar', 'Shawshank Redemption'];

  // Handle closing the Snackbar (Alert)
  const handleCloseAlert = () => {
    setAlert({ open: false, message: '', severity: '' });
  };

  // Show alert
  const showAlert = (message, severity) => {
    setAlert({ open: true, message, severity });
  };

  // First Step API call to submit user details
  const handleNext = async () => {
    if (step === 1) {
      const combinedJobTitles = [...userData.job, ...(userData.otherProfession || [])];

      // Validate Full name, Job Titles, and DOB
      if (!userData.fullname || !userData.dob || (userData.job.length + (userData.otherProfession?.length || 0)) === 0) {
        showAlert('Full name, Date of Birth, and Job titles are required.', 'error');
        return;
      }

      // Calculate the user's age based on the entered DOB
      const today = new Date();
      const birthDate = new Date(userData.dob);
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDifference = today.getMonth() - birthDate.getMonth();

      // Adjust age if the birthday hasn't occurred yet this year
      if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }

      // If the user is under 18
      if (age < 18) {
        setIsUnder18(true);
        // Save the profile and show the under-18 message
        try {
          const response = await axios.post('/onboarding', {
            userId,
            fullName: userData.fullname,
            jobTitles: combinedJobTitles,
            DOB: userData.dob, // Save the DOB
          });

          if (response.status === 200) {
            showAlert('Thank you for showing interest. We will contact you once you are 18+', 'info');
          } else {
            showAlert(response.data.message || 'Error updating profile.', 'error');
          }
        } catch (error) {
          showAlert('Failed to update profile for under-18 users. Please try again later.', 'error');
        } finally {
          setLoading(false);
        }
        return; // Stop execution here if under 18
      }

      setLoading(true);

      // Timeout to stop the spinner after 3 seconds
      const spinnerTimeout = setTimeout(() => {
        setLoading(false);
      }, 5000);

      try {
        const response = await axios.post('/onboarding', {
          userId,
          fullName: userData.fullname,
          jobTitles: combinedJobTitles,
          DOB: userData.dob, // Include DOB in the profile update
        });
        clearTimeout(spinnerTimeout); // Clear timeout if API finishes earlier than 3 seconds


        if (response.status === 200) {
          // If the user is under 18
          if (age < 18) {
            setIsUnder18(true); // Trigger the black screen
          } else {
            // For users 18 and older, proceed as usual
            showAlert('Profile updated successfully!', 'success');
            //Hardcoded referral code
            if (referralCode === "67596272d2556ca905aeee4a") {
              setStep(5); // Move to step 5 if referral code matches
            } else {
              setStep(2); // Otherwise, proceed to step 2
            }
          }
        } else {
          showAlert(response.data.message || 'Error updating profile.', 'error');
        }
      } catch (error) {
        showAlert('Failed to update profile. Please try again later.', 'error');
      } finally {
        setLoading(false);
      }
    }
    else if (step === 6) {
      // Validate the idea length
      if (idea.length < 20 || idea.length > 200) {
        showAlert('Your idea must be between 20 and 200 characters.', 'error');
        return;
      }
      setLoading(true); // Show loading spinner
      try {
        // Make a POST request to the backend to submit the idea
        const response = await axios.post('/onboarding/submit', {
          userId,
          referralCode,
          idea,
          userName: userData.fullname,
        });

        if (response.status === 200) {
          showAlert('Congratulations! Your idea has been successfully submitted.', 'success');
          setTimeout(() => setStep(2), 50); // Proceed to the next step
        } else {
          showAlert(response.data.message || 'Failed to submit the idea.', 'error');
        }
      } catch (error) {
        console.error('Error submitting idea:', error);
        showAlert('Server error: Unable to submit the idea. Please try again later.', 'error');
      } finally {
        setLoading(false); // Hide loading spinner
      }
    } else if (step === 2) {
      // Validate
      if (!userData.title || !userData.logLine || userData.genre.length === 0) {
        showAlert('Title, Logline and Genre are required.', 'error');
        return;
      }

      setLoading(true);

      try {
        const response = await axios.post('/content/originalIp', {
          userId,
          name: userData.title,
          genre: userData.genre,
          logLine: userData.logLine,
          privacy: false
        });
        // console.log("orignalIP_ID: ", response.data._id)
        setIp_id(response.data._id)

        if (response.status === 200) {
          showAlert('Originap Ip created successfully!', 'success');
          // handleUploadImage()
        } else {
          showAlert(response.data.message || 'Error creating ORiginal IP profile.', 'error');
        }
      } catch (error) {
        showAlert('Failed to update profile. Please try again later.', 'error');
      } finally {
        setLoading(false);
      }
    } else {
      setStep(step + 1); // Just move to the next step for others
    }
  };

  const generateImage = async () => {
    setImageLoading(true);
    clearTimeout(loadingTimeout); // Clear any existing timeout

    // Set a timeout to check if the loading takes more than 10 seconds
    const newTimeout = setTimeout(() => {
      setLoadingMessage('Hang on, It is taking more than expected...');
    }, 10000); // 10 seconds delay
    setLoadingTimeout(newTimeout);

    try {
      const response = await axios.post('/generate-poster', {
        logLine: userData.logLine,
        genre: userData.genre,
        format: '',
        title: userData.title,
        other_remarks: '',
        userId: userId,
        ip_id: ip_id
      });

      setImageData(response.data.imageData);
      setLoadingMessage(''); // Clear any loading message
    } catch (error) {
      console.error('Error generating the image:', error);
      showAlert('Failed to generate the image. Please try again later.', 'error');
    } finally {
      setImageLoading(false);
      clearTimeout(newTimeout); // Clear the timeout
    }
  };

  const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1], bstr = atob(arr[1]);
    let n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const handleUploadImage = async () => {
    setLoading(true); // Start loading
    try {
      // Convert the base64 image to a file
      const file = dataURLtoFile(`data:image/jpeg;base64,${imageData}`, `${userData.title}-${Date.now()}.png`);

      // Create FormData for file upload
      const formData = new FormData();
      formData.append('file', file);

      // Post to the upload API (adjust endpoint as needed)
      const response = await axios.post(`/upload/AI_Poster/${userData.title}-${Date.now()}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const fileLink = response.data.fileLink;
      // Display the file link (you can show this in the UI)
      // console.log('Image uploaded successfully: ', fileLink);
      showAlert(`Poster successfully uploaded`, 'success');

      // Make the PUT request to update the original IP poster with the new fileLink
      await axios.put(`/content/originalIp/${ip_id}/poster`, {
        newPoster: fileLink,
      });

      // Move to the next step after 1 second
      setTimeout(() => setStep(step + 1), 50);
    } catch (error) {
      console.error('Error uploading to S3:', error);
      showAlert('Failed to upload image.', 'error'); // Use showAlert here as well
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Trigger the logout sequence after typewriter completes
  // Trigger the logout sequence after typewriter completes
  useEffect(() => {
    if (isUnder18) {
      // Delay to start showing "Logging Out..." after typewriter ends
      setTimeout(() => {
        setShowLogoutText(true);

        // Sequentially show "Logging Out..." three times, with fade effect
        let count = 0;
        const interval = setInterval(() => {
          // Alternate between showing and hiding "Logging Out..."
          setLogoutMessage((prev) => (prev ? "" : "Logging Out..."));

          count++;

          // After showing 3 times (fade-in and fade-out), log out the user
          if (count === 6) { // 3 full fades (show + hide)
            clearInterval(interval);
            setTimeout(() => logout(), 1000); // Call logout after a short delay
          }
        }, 1000); // Duration between fade in and out
      }, 5000); // Start the logout sequence 5 seconds after typewriter ends
    }
  }, [isUnder18]);

  const handleSkip = () => {
    // console.log(`Step ${step} Skipped`);
    if (step < 4) setStep(step + 1);
  };

  const handleChipClick = (option, event) => {
    setUserData(prevData => {
      const isSelected = prevData.job.includes(option);

      // Create a new array based on whether we're selecting or deselecting
      const updatedJob = isSelected
        ? prevData.job.filter(job => job !== option)  // Deselect
        : [...prevData.job, option];                  // Select

      // Return the updated job state
      return {
        ...prevData,
        job: updatedJob,
      };
    });

    // Remove focus from the chip after click
    if (event && event.currentTarget) {
      event.currentTarget.blur();
    }
  };


  // Add an effect to track changes in selected jobs
  useEffect(() => {
    // console.log('Job state updated:', userData.job);
  }, [userData.job]);


  const handleMultiSelect = (name, value) => {
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFavoriteReason = (title, reason) => {
    setUserData((prevData) => ({
      ...prevData,
      reasons: { ...prevData.reasons, [title]: reason },
    }));
  };

  const renderStepOne = () => (
    <Fade in={step === 1} timeout={500} key={step}>
      <Box sx={{ maxWidth: '400px', width: '100%' }}>
        <TextField
          fullWidth
          label="Full Name"
          name="fullname"
          variant="outlined"
          sx={{ marginBottom: 2, bgcolor: theme.palette.background.paper, borderRadius: '8px' }}
          value={userData.fullname}
          onChange={(e) => setUserData({ ...userData, fullname: e.target.value })}
        />
        <Grid sx={{ marginBottom: 2, display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
          <Typography variant='h6' >DOB: </Typography>
          <input
            type="date"
            value={userData.dob ? new Date(userData.dob).toISOString().substring(0, 10) : ''}
            onChange={(e) => {
              const selectedDate = e.target.value ? new Date(e.target.value) : null;
              setUserData({ ...userData, dob: selectedDate }); // Update your userData state
            }}
            max={new Date().toISOString().split('T')[0]} // Restrict to today's date to prevent future dates
            style={{
              width: '100%',
              padding: '8px',
              fontSize: '16px',
              backgroundColor: 'white',
              color: 'black',
              border: '1px solid black',
            }}
            placeholder="Select Date of Birth"
          />
        </Grid>

        <Typography
          marginTop="10px"
          variant={"h6"}
          gutterBottom
          color={theme.palette.text.primary}
        >
          Choose your profession
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap', // Always wrap, two lines on mobile
            gap: 1,
            justifyContent: isMobile ? 'center' : 'flex-start', // Center on mobile
            marginBottom: 2,
          }}
        >
          {jobOptions.map((option) => (
            <Chip
              key={option}
              label={option}
              onClick={(event) => handleChipClick(option, event)}
              sx={{
                borderRadius: '50px',
                padding: '8px 8px',
                backgroundColor: userData.job.includes(option) ? 'green' : 'white',
                color: userData.job.includes(option) ? 'white' : 'black',
                border: `2px solid ${userData.job.includes(option) ? 'green' : 'black'}`,
                outline: 'none !important', // Important to remove default outline
                boxShadow: 'none !important', // Important to remove default focus shadow
                '&:hover': {
                  backgroundColor: userData.job.includes(option) ? 'darkgreen' : 'lightgray',
                  color: userData.job.includes(option) ? 'white' : 'black',
                  outline: 'none !important', // Ensure no outline on hover
                },
                '&:focus': {
                  outline: 'none !important', // Ensure no focus outline
                  boxShadow: 'none !important', // Prevent any shadow on focus
                },
                '&:active': {
                  backgroundColor: userData.job.includes(option) ? 'darkgreen' : 'lightgray',
                  outline: 'none !important',
                },
                minWidth: 'auto',
                flexGrow: 0,
                flexShrink: 0,
                marginBottom: '8px',
                textAlign: 'center',
              }}
            />

          ))}
        </Box>
        <Autocomplete
          multiple
          options={otherProfessionOptions}  // Replace with your other profession array
          value={userData.otherProfession}  // You will need to add this field to your state
          onChange={(event, newValue) => handleMultiSelect('otherProfession', newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Other Profession"
              variant="outlined"
              sx={{ marginBottom: 2, bgcolor: theme.palette.background.paper, borderRadius: '8px' }}
            />
          )}
          sx={{ marginBottom: 2 }}
        />

        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Button variant="contained" fullWidth onClick={handleNext} sx={{ borderRadius: '8px' }}>
            Next
          </Button>
        )}
      </Box>
    </Fade>
  );

  const renderStepFive = () => (
    <Fade in={step === 5} timeout={500} key={step}>
      <Box sx={{ maxWidth: '400px', width: '100%' }}>
        <Typography variant={isMobile ? "h5" : "h4"} gutterBottom color={theme.palette.text.primary}>
          Event Details
        </Typography>
        <Box
          sx={{
            padding: 2,
            border: '1px solid',
            borderRadius: '8px',
            marginBottom: 2,
            bgcolor: theme.palette.background.paper,
          }}
        >
          <Typography variant="h6" color={theme.palette.text.primary}>{referralEvent.name}</Typography>
          <Typography variant="body1" color={theme.palette.text.secondary}>
            From: {new Intl.DateTimeFormat('en-US', { dateStyle: 'long', timeStyle: 'short' }).format(new Date(referralEvent.fromDate))}
          </Typography>
          <Typography variant="body1" color={theme.palette.text.secondary}>
            To: {new Intl.DateTimeFormat('en-US', { dateStyle: 'long', timeStyle: 'short' }).format(new Date(referralEvent.toDate))}
          </Typography>          <Typography variant="body1" color={theme.palette.text.secondary}>{referralEvent.eventOwner.ownerName}</Typography>
          <Typography variant="body2" color="primary">
            <a
              href={referralEvent.documentation[0] || "https://docs.google.com/document/d/1P8OF-tiaHPXeGGrOKuwcyS2GVc6L_ucyeS8mn8KAWQE/edit?usp=sharing"}
              target="_blank"
              rel="noopener noreferrer"
            >
              Click for More details
            </a>

          </Typography>
        </Box>

        <FormControlLabel
          control={
            <Checkbox
              checked={userData.privacy}
              onChange={(e) => setUserData({ ...userData, privacy: e.target.checked })}
            />
          }
          label={
            <Typography variant="body2">
              I agree to the{' '}
              <a
                href={referralEvent.tAndc || "https://docs.google.com/document/d/1Yx3igO-Aiz9TH7HAgPNog7n810o5PZB6Bbtj-vBahJY/edit?usp=sharing"}
                target="_blank"
                rel="noopener noreferrer"
                style={{ fontWeight: 'bold', textDecoration: 'none', color: 'inherit' }}
              >
                Terms and Conditions
              </a>
            </Typography>
          }
        />


      <Button
        variant="contained"
        fullWidth
        disabled={!userData.privacy} // Disable until the checkbox is checked
        onClick={() => setStep(6)} // Move to the next step
        sx={{ borderRadius: '8px', marginTop: 2 }}
      >
        CONTINUE TO SUBMIT YOUR IDEA
      </Button>
    </Box>
    </Fade >
  );

const renderStepSix = () => (
  <Fade in={step === 6} timeout={500} key={step}>
    <Box sx={{ maxWidth: '400px', width: '100%' }}>
      <Typography variant={isMobile ? "h5" : "h4"} gutterBottom color={theme.palette.text.primary}>
        Submit Your Idea
      </Typography>
      {/* Hard Coded Refereal code Idea description */}
      <Typography variant="body2" color={theme.palette.text.secondary} gutterBottom>
        Your idea should be a minimum of 20 words and a maximum of 200 words.
      </Typography>
      <TextField
        fullWidth
        label="Your Idea"
        multiline
        rows={4}
        value={idea}
        onChange={(e) => setIdea(e.target.value)}
        variant="outlined"
        sx={{ marginTop: 2, marginBottom: 2, bgcolor: theme.palette.background.paper, borderRadius: '8px' }}
        error={idea.length < 20 || idea.length > 200} // Show error if out of range
        helperText={
          idea.length < 20
            ? "Your idea must be at least 20 words."
            : idea.length > 200
              ? "Your idea must not exceed 200 words."
              : ""
        }
      />
      <Button
        variant="contained"
        fullWidth
        disabled={idea.length < 20 || idea.length > 200} // Disable if validation fails
        onClick={async () => {
          handleNext();
        }}
        sx={{ borderRadius: '8px' }}
      >
        Submit Idea and Continue
      </Button>
    </Box>
  </Fade>
);

const renderStepTwo = () => (
  <Fade in={step === 2} timeout={500} key={step}>
    <Box sx={{ maxWidth: '400px', width: '100%' }}>
      {ip_id ? (
        <>
          <Box
            sx={{
              display: 'flex',  // Enables flexbox layout
              flexDirection: 'row',  // Aligns children in a row
              alignItems: 'center',  // Vertically centers the typography elements
              gap: 1,  // Adds space between the typography elements
            }}
          >
            <Typography variant={isMobile ? "body1" : "h6"} gutterBottom color={theme.palette.text.primary}>
              Your Story
            </Typography>
            <Typography variant={isMobile ? "h5" : "h3"} gutterBottom color={theme.palette.text.primary}>
              {userData.title}
            </Typography>
          </Box>

          {imageData && (
            <>
              {loading && !imageData ? <CircularProgress size={24} /> :
                <Box
                  sx={{
                    display: 'flex',  // Makes the child elements flex items
                    justifyContent: 'center',  // Centers the child elements horizontally
                    alignItems: 'center',  // Centers the child elements vertically
                    width: '100%',  // Ensures the box takes full width of its container
                    height: '250px',  // Fixed height for maintaining layout consistency
                    // backgroundColor: 'rgba(255, 255, 255, 0.1)',  // Translucent white background for glass effect
                    borderRadius: '12px',  // Rounded corners
                    backdropFilter: 'blur(10px)',  // Apply blur to elements behind the box for glassmorphism
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',  // Soft shadow for depth
                    overflow: 'hidden',  // Prevents content from spilling out
                  }}
                >
                  <img
                    src={`data:image/jpeg;base64,${imageData}`}
                    alt="Generated"
                    style={{
                      height: '200px',
                      width: '200px',
                      borderRadius: '12px'  // Ensures the image itself also has rounded corners
                    }}
                  />
                </Box>
              }
              <Button variant="contained" fullWidth onClick={handleUploadImage} sx={{ borderRadius: '8px', margin: 1 }}>
                Save and Continue
              </Button>
            </>
          )}
          {imageLoading ? (
            <>
              {loadingMessage && (
                <Typography color={'red.300'}>
                  {loadingMessage}
                </Typography>
              )}
              <Button variant="contained" fullWidth sx={{ borderRadius: '8px', margin: 1 }} disabled>

                <CircularProgress size={24} sx={{ color: 'white' }} />
              </Button>
            </>
          ) : (
            <>
              {imageData ? (
                <Button
                  variant="contained"
                  fullWidth
                  sx={{ borderRadius: '8px', margin: 1 }}
                  onClick={() => showAlert('You can regenerate the image in the Build Profile section.', 'info')}
                >
                  Customize or Regenerate Image (in Build Profile)
                </Button>
              ) : (
                <Button
                  variant="contained"
                  fullWidth
                  onClick={generateImage}
                  sx={{ borderRadius: '8px', margin: 1 }}
                >
                  Generate AI Image
                </Button>
              )}
            </>
          )}


          <Button variant="outlined" fullWidth onClick={handleSkip} sx={{ borderRadius: '8px', margin: 1 }}>
            Skip
          </Button>
        </>
      ) :
        (
          <>
            {/* <Typography variant="h4" gutterBottom color={theme.palette.text.primary}>Add Your Story</Typography> */}
            <TextField
              fullWidth
              label="Story Title"
              name="title"
              variant="outlined"
              sx={{ marginBottom: 2, bgcolor: theme.palette.background.paper, borderRadius: '8px' }}
              value={userData.title}
              onChange={(e) => setUserData({ ...userData, title: e.target.value })}
            />
            <TextField
              fullWidth
              label="Logline"
              name="logLine"
              variant="outlined"
              sx={{ marginBottom: 2, bgcolor: theme.palette.background.paper, borderRadius: '8px' }}
              value={userData.logLine}
              onChange={(e) => setUserData({ ...userData, logLine: e.target.value })}
            />
            <Autocomplete
              multiple
              options={genreOptions}  // <--- Set your list of genres here
              value={userData.genre}  // <--- Bind the selected value here
              onChange={(event, newValue) => handleMultiSelect('genre', newValue)}  // <--- Handle the array of selected genres
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox checked={selected} />
                  {option}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Genre"
                  variant="outlined"
                  fullWidth
                  sx={{ marginBottom: 2, bgcolor: theme.palette.background.paper, borderRadius: '8px' }}
                />
              )}
            />


            <Button
              variant="contained"
              fullWidth
              onClick={handleNext}
              sx={{ borderRadius: '8px', margin: 1 }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Continue'}
            </Button>

            <Button variant="outlined" fullWidth onClick={handleSkip} sx={{ borderRadius: '8px', margin: 1 }}>
              Skip
            </Button>
          </>
        )}
    </Box>
  </Fade>
);

// const {should set}} = () => (
//   <Fade in={step === {should set}} timeout={500} key={step}>
//     <Box sx={{ maxWidth: '400px', width: '100%' }}>
//       {/* <Typography variant={isMobile ? "h6" : "h4"} gutterBottom color={theme.palette.text.primary}>Favorite Creations</Typography> */}
//       <Typography variant="body1" color={theme.palette.text.primary}>Select up to 4 favorite</Typography>
//       {favoriteOptions.map((option) => (
//         <Box key={option} sx={{ marginBottom: 1 }}>
//           <FormControlLabel
//             control={<Checkbox checked={userData.favorite.includes(option)} onChange={() => handleMultiSelect('favorite', [...userData.favorite, option])} />}
//             label={option}
//           />
//           {userData.favorite.includes(option) && (
//             <TextField
//               fullWidth
//               label={`Why do you like ${option}?`}
//               variant="outlined"
//               sx={{ marginTop: 1, bgcolor: theme.palette.background.paper, borderRadius: '8px' }}
//               onChange={(e) => handleFavoriteReason(option, e.target.value)}
//             />
//           )}
//         </Box>
//       ))}

//       <Button variant="outlined" fullWidth onClick={handleSkip} sx={{ marginBottom: 2, borderRadius: '8px' }}>Skip</Button>
//       <Button variant="contained" fullWidth onClick={handleNext} sx={{ borderRadius: '8px' }}>Next</Button>
//     </Box>
//   </Fade>
// );

const renderStepThree = () => (
  <Fade in={step === 3} timeout={500} key={step}>
    <Box sx={{ maxWidth: '400px', width: '100%' }}>
      {/* <Typography variant="h4" gutterBottom color={theme.palette.text.primary}>You're Done!</Typography> */}
      <Typography variant="h6" color={theme.palette.text.primary}>You have successfully signed up to Justwrite!</Typography>

      {/* Display Share button only if there are favorite creations or story */}
      {userData.title || userData.favorite.length > 0 ? (
        <>
          <PublicShare pubProfileLink={currentPubProfileLink} isLabel={true} />
        </>
      ) : null}

      <Button
        variant="outlined"
        fullWidth
        sx={{ marginTop: 2, borderRadius: '8px' }}
        onClick={async () => {
          await setIsOnboarding(false); // Update the onboarding status
          navigate('/buildprofile');
        }}
      >
        Continue Building Profile
      </Button>
      <Button
        variant="contained"
        fullWidth
        sx={{ marginTop: 2, borderRadius: '8px' }}
        onClick={async () => {
          await setIsOnboarding(false); // Update the onboarding status
          navigate('/');
        }}
      >
        View Profile
      </Button>
    </Box>
  </Fade>
);

if (isUnder18) {
  return (
    <Box
      sx={{
        bgcolor: 'black',
        color: 'white',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        flexDirection: 'column'
      }}
    >
      <Typography variant={isMobile ? 'h6' : 'h2'} gutterBottom>
        <Typewriter
          key={step}
          words={["Thank you for showing interest. We will contact you once you are 18+"]}
          loop={1}
          cursor={false}
          typeSpeed={20}
          deleteSpeed={50}
          delaySpeed={700}
        />
      </Typography>

      {showLogoutText && (
        <Typography
          variant="h6"
          sx={{
            opacity: logoutMessage ? 1 : 0, // Control visibility through opacity
            transition: 'opacity 0.5s ease-in-out', // Smooth transition for fade effect
          }}
        >
          {logoutMessage}
        </Typography>

      )}
      {/* <CircularProgress sx={{ marginTop: '16px', color: 'white' }} /> */}
    </Box >
  );
}
return (
  <Box sx={{ bgcolor: theme.palette.background.default, color: theme.palette.text.primary, minHeight: '100vh', p: 4, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
    <Box
      sx={{
        position: 'fixed', // Fixed position relative to the viewport
        top: 10, // 10px from the top of the viewport
        right: 10, // 10px from the right of the viewport
        paddingTop: '10px', // 10px padding at the top
        paddingRight: '10px', // 10px padding on the right
        zIndex: 1200 // Make sure it's above most other content; adjust the value based on your other elements
      }}
    >
      <Tooltip title="Log Out" placement="bottom">
        <IconButton
          color="primary"
          onClick={logout}
        >
          <LogoutIcon sx={{ color: 'inherit' }} /> {/* Color inherits from IconButton */}
        </IconButton>
      </Tooltip>
    </Box>
    <Fade in={true} timeout={800}>
      <Typography
        variant={isMobile ? 'h4' : 'h2'}
        gutterBottom
        sx={{
          marginBottom: isMobile ? '30px' : '80px', // Responsive gap between message and content
          textAlign: 'left', // Left-align the welcome message
          paddingLeft: isMobile ? '' : '',
          transition: 'all 0.5s ease-in-out', // Smooth transition for padding and margin
        }}
        color={theme.palette.text.primary}
      >
        <Typewriter
          key={step}
          words={
            step === 1
              ? ['Welcome to Justwrite!']
              : step === 2
                ? referralCode === '66fd319c333ac0a91e3c4df8'
                  ? ['Add an Anime-Worthy Story below!']
                  : ['Add Film/Series/Game worthy Story below!']
                : step === 3
                  ? [`Congratulations ${userData.fullname}!`]
                  : ['Welcome to Justwrite!']
          }
          loop={1}
          cursor={false}
          cursorStyle="_"
          typeSpeed={40}
          deleteSpeed={50}
          delaySpeed={700}
        />
      </Typography>
    </Fade>

    {/* <Typography>{`Referral Code: ${referralCode}`}</Typography> */}

    {step === 1 && renderStepOne()}
    {step === 5 && renderStepFive()}
    {step === 6 && renderStepSix()}
    {step === 2 && renderStepTwo()}
    {step === 3 && renderStepThree()}
    {/* {step === {should set} && {should set}}} */}

    {/* Snackbar for success/error messages */}
    <Snackbar
      open={alert.open}
      autoHideDuration={3000}
      onClose={handleCloseAlert}
    >
      <Alert onClose={handleCloseAlert} severity={alert.severity}>
        {alert.message}
      </Alert>
    </Snackbar>
  </Box>
);
};

export default Onboarding;
