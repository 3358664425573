import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Typography, CardMedia, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DescriptionIcon from '@mui/icons-material/Description';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { useAxios } from '../../contexts/AxiosContext'; // For making API requests
import { useMediaQuery } from '@mui/material';
import { useTheme } from "../../contexts/ThemeContext";

const EventDetailsDialog = ({ eventDetails, open, onClose, userId, updateEventDetails }) => {
    const axios = useAxios();
    const { theme } = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect if screen is mobile size
    const [optedIn, setOptedIn] = useState(eventDetails.optedIn);
    const [read, setRead] = useState(eventDetails.read);
    const [reminderDate, setReminderDate] = useState(eventDetails.reminderDate || '');
    const [errorMessage, setErrorMessage] = useState('');

    // Handle opt-in/out action
    const handleOptInOut = async (optIn) => {
        if (!optIn) {
            const confirmation = window.confirm('Are you sure you want to opt out of this event?');
            if (!confirmation) {
                return; // User canceled the opt-out
            }
        }

        try {
            await axios.post(`/userevents/opt-in-out/${userId}/${eventDetails.eventId?._id}`, { optIn });

            // Safe state update with validation
            const updatedDetails = { ...eventDetails, optedIn: optIn };
            setOptedIn(optIn);
            updateEventDetails(updatedDetails);
        } catch (error) {
            console.error('Error opting in/out:', error);

            // Set the error message to display in the UI
            if (error.response?.status === 400 && error.response.data.message === 'User must have at least one original IP to opt-in') {
                setErrorMessage('You must have at least one original IP address to opt-in.');
            } else if (error.response?.status === 404) {
                setErrorMessage('User event data not found.');
            } else {
                setErrorMessage('Failed to update opt-in status. Please try again later.');
            }
        }
    };



    // Handle mark as read/unread
    const handleMarkRead = async (read) => {
        try {
            await axios.post(`/userevents/read/${userId}/${eventDetails.eventId?._id}`, { read });

            // Safe state update with validation
            const updatedDetails = { ...eventDetails, read };
            setRead(read);
            updateEventDetails(updatedDetails);
        } catch (error) {
            console.error('Error marking as read:', error);
        }
    };


    // Handle setting a reminder
    const handleSetReminder = async () => {
        try {
            await axios.post(`/userevents/reminder/${userId}/${eventDetails.eventId._id}`, { reminderDate });
            alert('Reminder set successfully');
            updateEventDetails({ reminderDate }); // Update parent state
        } catch (error) {
            console.error('Error setting reminder:', error);
        }
    };

    useEffect(() => { handleMarkRead(true); }, [open]);


    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            PaperProps={{ style: { width: '90vw', height: '90vh' } }}  // Set width and height to be 75% of the viewport
        >
            <DialogTitle style={{ fontWeight: 'bold' }}>{eventDetails.eventId.name}</DialogTitle>
            <DialogContent style={{ display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}>  {/* Disable global scrolling */}
                {eventDetails.eventId.images?.map((image, index) => (
                    <CardMedia
                        key={index}
                        component="img"
                        height="300px"
                        image={image || 'https://via.placeholder.com/400x300.png?text=No+Image'}
                        alt={eventDetails.eventId.name}
                        style={{ width: 'auto', maxWidth: '300px', marginRight: '10px' }}  // Thumbnail style
                    />

                )) || <Typography>No Images Available</Typography>}
                <Typography variant="h6">Episode: {eventDetails.eventId.eventEpisode || 'N/A'}</Typography>
                <Typography variant="body1">Description: {eventDetails.eventId.description || 'N/A'}</Typography>
                <Typography variant="body1">Location: {eventDetails.eventId.location || 'N/A'}</Typography>
                <Typography variant="body1">From: {new Date(eventDetails.eventId.fromDate).toLocaleString()}</Typography>
                <Typography variant="body1">To: {new Date(eventDetails.eventId.toDate).toLocaleString()}</Typography>
                <Typography variant="body1">Description: {eventDetails.eventId.description || 'N/A'}</Typography>

                {/* Documentation */}
                {eventDetails.eventId.documentation?.length > 0 ? (
                    <div style={{ maxHeight: '150px', overflowY: 'auto', marginTop: '10px' }}>  {/* Scrollable if many documents */}
                        {eventDetails.eventId.documentation.map((doc, index) => (
                            <Button
                                key={index}
                                href={doc}
                                target="_blank"
                                rel="noopener noreferrer"
                                variant="outlined"  // This gives it the outlined style
                                style={{ marginRight: '10px', padding: '8px' }}
                                startIcon={<DescriptionIcon />}  // Optional, add an icon if desired
                            >
                                Document {index + 1}
                            </Button>

                        ))}
                    </div>
                ) : (
                    <Typography variant="body2">Documentation: N/A</Typography>
                )}

                {/* Sponsors */}
                {eventDetails.eventId.sponsors?.length > 0 ? (
                    <Accordion style={{ marginTop: '10px' }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6">Sponsors</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ maxHeight: '150px', overflowY: 'auto' }}>  {/* Scrollable if long */}
                            {eventDetails.eventId.sponsors.map((sponsor, index) => (
                                <div key={index}>
                                    <Typography variant="body1">{sponsor.name || 'N/A'}</Typography>
                                    <Typography variant="body2">Contribution: {sponsor.contribution || 'N/A'}</Typography>
                                </div>
                            ))}
                        </AccordionDetails>
                    </Accordion>
                ) : (
                    <Typography variant="body2">Sponsors: N/A</Typography>
                )}
            </DialogContent>


            <DialogActions style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '10px',
                width: '100%'
            }}>
                {/* <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                    <input
                        type="datetime-local"
                        value={reminderDate}
                        onChange={(e) => setReminderDate(e.target.value)}
                        style={{ width: '150px' }}
                    />
                    <Button onClick={handleSetReminder}
                        variant="outlined"
                        style={{ marginLeft: '10px' }}
                        startIcon={<NotificationsActiveIcon />}  >
                        Set Reminder
                    </Button>
                </div> */}
                <Button
                    variant="outlined"
                    onClick={() => handleMarkRead(!read)}
                    style={{ marginLeft: '10px' }}
                >
                    {read ? 'Mark as Unread' : 'Mark as Read'}
                </Button>
                <Button
                    variant="contained"
                    onClick={() => handleOptInOut(!optedIn)}
                    style={{ borderColor: optedIn ? 'red' : 'green', color: optedIn ? 'red' : 'green' }}
                >
                    {optedIn ? 'Opt Out' : 'Opt In'}
                </Button>

            </DialogActions>

            <DialogContent>
                {/* Other dialog content */}

                {errorMessage && (
                    <Typography color="error" variant="body2" style={{ marginTop: '10px' }}>
                        {errorMessage}
                    </Typography>
                )}
            </DialogContent>




        </Dialog>
    );
};

export default EventDetailsDialog;
