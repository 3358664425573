import React, { useState } from 'react';
import {
    List,
    ListItem,
    ListItemText,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    IconButton,
} from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EventIcon from '@mui/icons-material/Event';
import PeopleIcon from '@mui/icons-material/People';
import ReferralIcon from '@mui/icons-material/PeopleOutline';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const OperationsSidebar = ({ setView }) => {
    const [isCollapsed, setIsCollapsed] = useState(false);

    // Toggle the collapsed state
    const toggleSidebar = () => setIsCollapsed((prev) => !prev);

    return (
        <div
            style={{
                width: isCollapsed ? '80px' : '250px', // Dynamic width
                height: '100vh',
                background: '#333',
                color: '#fff',
                overflow: 'hidden',
                transition: 'width 0.3s ease', // Smooth transition
            }}
        >
            {/* Sidebar Header */}
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: isCollapsed ? 'center' : 'space-between',
                    padding: '10px',
                    borderBottom: '1px solid #444',
                }}
            >
                {!isCollapsed && <Typography variant="h6" style={{ color: '#fff' }}>Operations</Typography>}
                <IconButton onClick={toggleSidebar} style={{ color: '#fff' }}>
                    {isCollapsed ? <MenuIcon /> : <ChevronLeftIcon />}
                </IconButton>
            </div>

            {/* Sidebar Content */}
            <List component="nav" style={{ padding: isCollapsed ? '10px 0' : '20px 0' }}>
                {/* Dashboard */}
                <ListItem button onClick={() => setView('dashboard')}>
                    <DashboardIcon style={{ marginRight: isCollapsed ? 0 : '10px' }} />
                    {!isCollapsed && <ListItemText primary="Dashboard" />}
                </ListItem>

                {/* Referral Management */}
                <ListItem button onClick={() => setView('referralManagement')}>
                    <ReferralIcon style={{ marginRight: isCollapsed ? 0 : '10px' }} />
                    {!isCollapsed && <ListItemText primary="Referral" />}
                </ListItem>

                {/* Event Management */}
                <ListItem button onClick={() => setView('eventManagement')}>
                    <EventIcon style={{ marginRight: isCollapsed ? 0 : '10px' }} />
                    {!isCollapsed && <ListItemText primary="Events" />}
                </ListItem>

                {/* Teams Accordion
                <Accordion
                    defaultExpanded={!isCollapsed}
                    style={{
                        background: 'transparent',
                        boxShadow: 'none',
                        color: '#fff',
                    }}
                >
                    <AccordionSummary
                        expandIcon={!isCollapsed && <ExpandMoreIcon style={{ color: '#fff' }} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <PeopleIcon style={{ marginRight: isCollapsed ? 0 : '10px' }} />
                        {!isCollapsed && <Typography>Teams</Typography>}
                    </AccordionSummary>
                    <AccordionDetails>
                        <List component="nav">
                            <ListItem button onClick={() => setView('teamManagement')}>
                                <ListItemText primary="Team Management" />
                            </ListItem>
                            <ListItem button onClick={() => setView('teamMembership')}>
                                <ListItemText primary="Team Membership" />
                            </ListItem>
                        </List>
                    </AccordionDetails>
                </Accordion> */}
            </List>
        </div>
    );
};

export default OperationsSidebar;
