import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // Fix import by removing destructuring
import logger from '../logger'; // Update the path to your logger file
import { useAxios } from '../contexts/AxiosContext';
import errorCodes from '../utils/errorCodes.json';
import { Snackbar, Alert } from "@mui/material"; // Assuming you are using MUI for alerts
import { registerServiceWorkerAndSubscribe } from '../utils/pushNotifications';
import Onboarding from '../components/onboarding/Onboarding';

const LoginContext = createContext();

export const useLogin = () => useContext(LoginContext);

export const LoginProvider = ({ children }) => {
    const [user, setUser] = useState(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const decoded = jwtDecode(token);
            if (decoded.exp * 1000 > Date.now()) {
                return {
                    userId: decoded.user.id,
                    username: decoded.user.name,
                    isNewUser: decoded.user.isNewUser || false,
                    globalRoles: decoded.user.globalRoles || [],
                    isOnboarding: decoded.user.isOnboarding || false,
                    referralCode: decoded.user.referralCode || '',
                };
            }
        }
        return null;
    });
    const [error, setError] = useState('');
    const [showResend, setShowResend] = useState(false);
    const [pubProfileLink, setpubProfileLink] = useState('')
    const axios = useAxios()
    const navigate = useNavigate();
    const [isNewUser, setIsNewUser] = useState(false)
    const [alert, setAlert] = useState({ message: '', severity: '', open: false });

    const logout = useCallback(() => {
        localStorage.removeItem('token');
        localStorage.removeItem('userData');
        localStorage.removeItem('isSubscribed');
        setUser(null);
        logger('User logged out', 'info', ['env:production', 'event:logout', `userId:${user?.userId}`], 'soozh-beta-frontend');

        navigate('/');
    }, [navigate, user]);


    useEffect(() => {
        if (user) {
            const token = localStorage.getItem('token');
            const decoded = jwtDecode(token);
            if (decoded.exp * 1000 < Date.now()) {
                logout();
            }
        }
    }, [user, logout]);

    useEffect(() => {
        const validateOnboardingStatus = () => {
            const storedUserData = localStorage.getItem('userData');
            if (storedUserData) {
                const parsedData = JSON.parse(storedUserData);

                // Sync with context only if `isOnboarding` differs
                if (parsedData.isOnboarding !== user?.isOnboarding) {
                    setUser((prevUser) => ({
                        ...prevUser,
                        isOnboarding: parsedData.isOnboarding,
                    }));
                }
            }
        };

        validateOnboardingStatus();
    }, []);

    const setIsOnboarding = async (newIsOnboardingStatus) => {
        try {
            // API call to update the onboarding status on the server
            await axios.put(`/auth/${user.userId}/onboarding`, { isOnboarding: newIsOnboardingStatus });

            // Update the user state
            setUser((prevUser) => {
                const updatedUser = { ...prevUser, isOnboarding: newIsOnboardingStatus };
                localStorage.setItem('userData', JSON.stringify(updatedUser)); // Sync with localStorage
                return updatedUser;
            });

            logger('User onboarding status updated', 'info', ['env:production', 'event:onboarding', `userId:${user?.userId}`], 'soozh-beta-frontend');
        } catch (error) {
            console.error('Failed to update onboarding status:', error);
        }
    };


    const login = async (email, password, redirectPath = '', keepMeSignedIn) => {
        try {
            const response = await axios.post(`/auth/login`,
                { email, password, keepMeSignedIn },
                { headers: { 'Content-Type': 'application/json' } }
            );

            const data = response.data;

            if (response.status !== 200) {
                const errorMessage = errorCodes[data.code]?.message || 'An error occurred while logging in. Please try again.';
                setError(errorMessage);
                setShowResend(data.code === 'EMAIL_NOT_VERIFIED');
                logger('Failed login attempt', 'error', ['env:production', 'event:login', 'status:failed'], 'soozh-beta-frontend');
                return;
            }

            if (data.redirectURL) {
                window.location.href = data.redirectURL; // Redirect to consent page
                return;
            }

            if (data.token) {
                localStorage.setItem('token', data.token);
                localStorage.setItem('userData', JSON.stringify({
                    userId: data.user._id,
                    username: data.user.name,
                    isNewUser: data.user.isNewUser,
                    globalRoles: data.user.globalRoles,  // Save globalRoles from response
                    pubProfileLink: data.user.pubProfileLink,
                    isOnboarding: data.user.isOnboarding,
                    referralCode: data.user.referralCode || '',

                }));

                // Set user state
                setUser({
                    userId: data.user._id,
                    username: data.user.name,
                    isNewUser: data.user.isNewUser,
                    globalRoles: data.user.globalRoles,
                    isOnboarding: data.user.isOnboarding,
                    referralCode: data.user.referralCode || '',

                });

                // After successful login, subscribe the user to push notifications
                const VAPID_PUBLIC_KEY = process.env.REACT_APP_VAPID_PUBLIC_KEY;
                if (!localStorage.getItem('isSubscribed')) {
                    await registerServiceWorkerAndSubscribe(VAPID_PUBLIC_KEY, axios, data.user._id);
                    localStorage.setItem('isSubscribed', 'true'); // Mark as subscribed
                }


                // Set pubProfileLink state from user object in the response
                setpubProfileLink(data.user.pubProfileLink);  // <-- Correct access to pubProfileLink

                setIsNewUser(data.user.isNewUser); // Update isNewUser state
                logger(`User logged in: ${JSON.stringify({ userId: data.user._id, username: data.user.name })}`, 'info', ['env:production', 'event:login', 'status:success'], 'soozh-beta-frontend');
                setError(''); // Clear any previous error messages
                setShowResend(false); // Hide resend button if login is successful
                if (data.user.isNewUser === true) {
                    setIsNewUser(true); // This should update the isNewUser state directly
                    // navigate(`/buildprofile`);
                    navigate(`/`); //as we use invbarding form
                } else {
                    setIsNewUser(false);
                    navigate(redirectPath);
                }
            }
        } catch (err) {
            if (err.response.status == 400) {
                const errorMessage = errorCodes[err.response.data.code]?.message || 'An error occurred while logging in. Please try again.'
                setAlert({ message: errorMessage, severity: 'info', open: true });

                // Optional: If you want a manual timer, you can close it after 3 seconds (3000 ms)
                setTimeout(() => {
                    setAlert({ ...alert, open: false });
                }, 3000); // Closes after 3 seconds

            }
            logger(`Error logging in: ${err.message}`, 'error', ['env:production', 'event:login', 'status:failed'], 'soozh-beta-frontend');
            console.error('Error logging in:', err);
        }
    };

    const googleLogin = useCallback(async (token) => {
        try {
            // Decode the token to extract user information
            const decoded = jwtDecode(token);

            if (!decoded.user || !decoded.user.id || !decoded.user.name) {
                throw new Error('Malformed token, missing user details');
            }

            // Save user details in localStorage
            localStorage.setItem('userData', JSON.stringify({
                userId: decoded.user.id,
                username: decoded.user.name,
                isNewUser: decoded.user.isNewUser,
                globalRoles: decoded.user.globalRoles || [],  // Ensure roles are added to the state
                pubProfileLink: decoded.user.pubProfileLink,
                isOnboarding: decoded.user.isOnboarding,
                referralCode: decoded.user.referralCode || '',
            }));

            // Update user state
            setUser({
                userId: decoded.user.id,
                username: decoded.user.name,
                isNewUser: decoded.user.isNewUser,
                globalRoles: decoded.user.globalRoles || ["user"],
                isOnboarding: decoded.user.isOnboarding,
                referralCode: decoded.user.referralCode || '',
            });

            setpubProfileLink(decoded.user.pubProfileLink);  // <-- Correct access to pubProfileLink
            setIsNewUser(decoded.user.isNewUser); // Update isNewUser state

            // After successful login, subscribe the user to push notifications
            const VAPID_PUBLIC_KEY = process.env.REACT_APP_VAPID_PUBLIC_KEY;
            if (!localStorage.getItem('isSubscribed')) {
                await registerServiceWorkerAndSubscribe(VAPID_PUBLIC_KEY, axios, decoded.user.id);
                localStorage.setItem('isSubscribed', 'true'); // Mark as subscribed
            }

            // Navigate based on whether the user is new or existing
            if (decoded.user.isNewUser) {
                navigate('/buildprofile');
            } else {
                navigate('/');
            }
        } catch (error) {
            console.error('Error logging in with Google:', error);
        }
    }, [navigate]);


    const toggleIsNewUser = async (userId, isNewUser) => {
        try {
            const response = await axios.post(`/auth/toggleIsNewUser/${userId}`, { isNewUser });
            if (response.status === 200) {
                setUser((prevUser) => ({
                    ...prevUser,
                    isNewUser,
                }));
                console.log('User status updated');
            } else {
                console.error('Failed to update user status:', response.data.message);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const fetchUserProfile = useCallback(async (userId) => {
        try {
            const response = await axios.get(`/profile/${userId}`);
            if (response.status === 200) {
                return response.data;
            } else {
                console.error('Failed to fetch user data:', response.data.message);
                return null;
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
            return null;
        }
    }, [axios]);

    const isLoggedIn = !!user;
    const userId = user ? user.userId : null;
    const username = user ? user.username : null;
    const globalRoles = user ? user.globalRoles : [];
    const isOnboarding = user ? user.isOnboarding : false;
    const referralCode = user ? user.referralCode : '';

    return (
        <LoginContext.Provider value={{ login, googleLogin, logout, isLoggedIn, userId, globalRoles, username, error, showResend, isNewUser, toggleIsNewUser, pubProfileLink, fetchUserProfile, isOnboarding, setIsOnboarding, referralCode }}>
            {isLoggedIn && isOnboarding ? (
                <Onboarding />
            ) : (
                children
            )}
            <Snackbar
                open={alert.open}
                autoHideDuration={6000}
                onClose={() => setAlert({ ...alert, open: false })}
            >
                <Alert onClose={() => setAlert({ ...alert, open: false })} severity={alert.severity}>
                    {alert.message}
                </Alert>
            </Snackbar>
        </LoginContext.Provider>
    );

};
