import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  OutlinedInput,
} from '@mui/material';
import { useLogin } from '../../../contexts/LoginContext';  // Assuming this provides the logged-in user
import { useAxios } from '../../../contexts/AxiosContext';

const CreateEventDialog = ({ open, onClose, onSuccess, existingEvent = null }) => {
  const axios = useAxios();
  const { userId } = useLogin();  // Logged-in user's ID
  const [referralPrograms, setReferralPrograms] = useState([]);
  const [selectedReferrals, setSelectedReferrals] = useState(existingEvent?.criteria?.referral || []);
  const [name, setName] = useState(existingEvent?.name || '');
  const [eventEpisode, setEventEpisode] = useState(existingEvent?.eventEpisode || '');
  const [description, setDescription] = useState(existingEvent?.description || '');
  const [location, setLocation] = useState(existingEvent?.location || '');
  const [fromDate, setFromDate] = useState(existingEvent?.fromDate || '');
  const [toDate, setToDate] = useState(existingEvent?.toDate || '');
  const [eventLink, setEventLink] = useState(existingEvent?.eventLink || '');
  const [images, setImages] = useState(existingEvent?.images?.join(',') || '');
  const [documentation, setDocumentation] = useState(existingEvent?.documentation?.join(',') || '');
  const [ownerName, setOwnerName] = useState(existingEvent?.eventOwner?.ownerName || '');
  const [tAndc, setTAndc] = useState(existingEvent?.tAndc || '');

  useEffect(() => {
    if (existingEvent) {
      setSelectedReferrals(existingEvent.criteria?.referral || []);
      setName(existingEvent.name || '');
      setEventEpisode(existingEvent.eventEpisode || '');
      setDescription(existingEvent.description || '');
      setLocation(existingEvent.location || '');
      setFromDate(existingEvent.fromDate || '');
      setToDate(existingEvent.toDate || '');
      setEventLink(existingEvent.eventLink || '');
      setImages(existingEvent.images?.join(',') || '');
      setDocumentation(existingEvent.documentation?.join(',') || '');
      setOwnerName(existingEvent.eventOwner?.ownerName || '');
      setTAndc(existingEvent.tAndc || '');
    } else {
      // Clear the state if there's no existing event
      setSelectedReferrals([]);
      setName('');
      setEventEpisode('');
      setDescription('');
      setLocation('');
      setFromDate('');
      setToDate('');
      setEventLink('');
      setImages('');
      setDocumentation('');
      setOwnerName('');
      setTAndc('');
    }
  }, [existingEvent]);


  const hasChanges = () => {
    return (
      name !== existingEvent?.name ||
      eventEpisode !== existingEvent?.eventEpisode ||
      description !== existingEvent?.description ||
      location !== existingEvent?.location ||
      fromDate !== existingEvent?.fromDate ||
      toDate !== existingEvent?.toDate ||
      eventLink !== existingEvent?.eventLink ||
      images !== (existingEvent?.images?.join(',') || '') ||
      documentation !== (existingEvent?.documentation?.join(',') || '') ||
      ownerName !== existingEvent?.eventOwner?.ownerName ||
      tAndc !== existingEvent?.tAndc
    );
  };

  // Fetch referral programs
  useEffect(() => {
    const fetchReferralPrograms = async () => {
      try {
        const response = await axios.get('/referral/list');
        setReferralPrograms(response.data.activePrograms);  // Only show active programs
      } catch (error) {
        console.error('Error fetching referral programs:', error);
      }
    };
    fetchReferralPrograms();
  }, [axios]);

  // Handle form submission
  const handleSubmit = async () => {
    const newEvent = {
      name,
      eventEpisode,
      description,
      location,
      fromDate,
      toDate,
      eventLink,
      images: images.split(',').map((img) => img.trim()),
      documentation: documentation.split(',').map((doc) => doc.trim()),
      createdBy: userId,
      eventOwner: { ownerName },
      criteria: { referral: selectedReferrals.map((ref) => ref._id) },
      tAndc,
    };

    try {
      if (existingEvent) {
        // Update event
        await axios.put(`/event/update/${existingEvent._id}`, newEvent);
        alert('Event updated successfully');
      } else {
        // Create new event
        await axios.post('/event/create', newEvent);
        alert('Event created successfully');
      }
      onSuccess();  // Trigger success callback
      onClose();  // Close the dialog
    } catch (error) {
      console.error('Error saving event:', error);
    }
  };


  // Handle referral selection (multi-select)
  const handleReferralChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedReferrals(
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle>
        {existingEvent ? `Edit Event: ${existingEvent.name}` : 'Create New Event'}
      </DialogTitle>
      <DialogContent>
        <TextField
          label="Event Name"
          fullWidth
          margin="dense"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <TextField
          label="Event Episode"
          fullWidth
          margin="dense"
          value={eventEpisode}
          onChange={(e) => setEventEpisode(e.target.value)}
          required
        />
        <TextField
          label="Description"
          fullWidth
          margin="dense"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <TextField
          label="Location"
          fullWidth
          margin="dense"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          required
        />
        <TextField
          label="From Date"
          type="datetime-local"
          fullWidth
          margin="dense"
          value={fromDate}
          onChange={(e) => setFromDate(e.target.value)}
          required
        />
        <TextField
          label="To Date"
          type="datetime-local"
          fullWidth
          margin="dense"
          value={toDate}
          onChange={(e) => setToDate(e.target.value)}
          required
        />
        <TextField
          label="Event Link"
          fullWidth
          margin="dense"
          value={eventLink}
          onChange={(e) => setEventLink(e.target.value)}
        />
        <TextField
          label="Images (Comma-separated URLs)"
          fullWidth
          margin="dense"
          value={images}
          onChange={(e) => setImages(e.target.value)}
        />
        <TextField
          label="Documentation (Comma-separated URLs)"
          fullWidth
          margin="dense"
          value={documentation}
          onChange={(e) => setDocumentation(e.target.value)}
        />
        <TextField
          label="Terms and Conditions"
          fullWidth
          margin="dense"
          value={tAndc}
          onChange={(e) => setTAndc(e.target.value)}
        />
        <TextField
          label="Event Owner Name"
          fullWidth
          margin="dense"
          value={ownerName}
          onChange={(e) => setOwnerName(e.target.value)}
          required
        />

        {/* Multi-select for Referral Programs */}
        <FormControl fullWidth margin="dense">
          <InputLabel>Referral Programs</InputLabel>
          <Select
            multiple
            value={selectedReferrals}
            onChange={handleReferralChange}
            input={<OutlinedInput id="select-multiple-chip" label="Referral Programs" />}
            renderValue={(selected) => (
              <div>
                {selected.map((referral) => (
                  <Chip key={referral._id} label={referral.eventName} />
                ))}
              </div>
            )}
            disabled={!!existingEvent} // Disable dropdown in edit mode
          >
            {referralPrograms.map((referral) => (
              <MenuItem key={referral._id} value={referral}>
                {referral.eventName} - {referral.eventEpisode}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={existingEvent && !hasChanges()} // Disable if no changes
        >
          {existingEvent ? 'Update Event' : 'Create Event'}
        </Button>

      </DialogActions>
    </Dialog>
  );
};

export default CreateEventDialog;
