import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Accordion, AccordionSummary, AccordionDetails, Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useAxios } from '../../../contexts/AxiosContext'; // Assuming Axios context is used
import CreateEventDialog from './CreateEventDialog';  // Import the dialog component
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const EventManagement = () => {
  const axios = useAxios();
  const [events, setEvents] = useState({
    activeEvents: [],
    expiredEvents: [],
    terminatedEvents: [],
  });
  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [eventUsers, setEventUsers] = useState([]);
  const [viewEventDialog, setViewEventDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  // State for confirmation dialog
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [confirmationAction, setConfirmationAction] = useState(null); // 'terminate' or 'reactivate'
  const [eventToConfirm, setEventToConfirm] = useState(null); // Holds the event ID

  // Fetch all events
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get('/event/list');
        console.log(response.data)
        setEvents(response.data);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };
    fetchEvents();
  }, [axios]);

  // Open the Create Event Dialog
  const handleOpenEditDialog = (event) => {
    setSelectedEvent(event);
    setCreateDialogOpen(true);
  };

  // Close the Create Event Dialog
  const handleCloseCreateDialog = () => {
    setCreateDialogOpen(false);
  };

  // Refresh the event list after a new event is created
  const handleEventCreated = async () => {
    handleCloseCreateDialog();
    try {
      const response = await axios.get('/event/list');
      setEvents(response.data);  // Refresh event list
    } catch (error) {
      console.error('Error refreshing events:', error);
    }
  };

  // Open event details dialog
  const handleViewEvent = (event) => {
    setSelectedEvent(event);
    setViewEventDialog(true);
  };

  // Fetch users associated with an event
  const fetchEventUsers = async (eventId) => {
    try {
      setLoading(true);
      const response = await axios.get(`/event/event-users/${eventId}`);
      console.log(response)
      setEventUsers(response.data.eventUsers);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching event users:', error);
      setLoading(false);
    }
  };

  const handleStatusChange = async (userId, newStatus) => {
    try {
      // Send the updated status to the server
      const payload = { _id: userId, selectionstatus: newStatus };
      console.log(payload)
      await axios.post('/event/update-selection-status', payload);

      // Update the local state for real-time UI feedback
      setEventUsers((prevUsers) =>
        prevUsers.map((user) =>
          user._id === userId ? { ...user, selectionstatus: newStatus } : user
        )
      );

      alert('Status updated successfully!');
    } catch (error) {
      console.error('Error updating status:', error);
      alert('Failed to update status. Please try again.');
    }
  };


  // Open the dialog to show users
  const handleViewUsers = (event) => {
    setSelectedEvent(event);
    fetchEventUsers(event._id);
    setOpenDialog(true);
  };

  const handleDownloadExcel = () => {
    if (eventUsers.length === 0) {
      alert('No data available to download.');
      return;
    }

    // Prepare data for Excel, including all the table fields
    const dataForExcel = eventUsers.map((user) => ({
      'User Name': user.userId?.name || 'N/A',
      'Public Profile': user.userId?.pubProfileLink
        ? `http://justwrite.soozh.app/p/${user.userId.pubProfileLink}`
        : 'N/A',
      Idea: user.idea || 'N/A',
      'Uploaded Links': user.uploads?.length > 0 ? user.uploads.join(', ') : 'N/A',
      'Created At': user.createdAt ? new Date(user.createdAt).toLocaleString() : 'N/A',
      'Updated At': user.updatedAt ? new Date(user.updatedAt).toLocaleString() : 'N/A',
      Status: user.selectionstatus || 'N/A',
    }));

    // Create a worksheet and workbook
    const worksheet = XLSX.utils.json_to_sheet(dataForExcel);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Event Users');

    // Export the workbook to a file
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(data, `event_users_${selectedEvent?._id || 'unknown'}.xlsx`);
  };

  // Select or Deselect a user for an event
  const handleSelectDeselect = async (userId, eventId, isSelected) => {
    try {
      const payload = { userId, eventId, eventName: selectedEvent?.name ? selectedEvent.name : eventId };
      if (isSelected) {
        // Call the deselect API
        await axios.post('/event/deselect-user-event', payload);
      } else {
        // Call the select API
        await axios.post('/event/select-user-event', payload);
      }

      // Refresh users after action
      fetchEventUsers(eventId);
      alert(isSelected ? 'User deselected successfully' : 'User selected successfully');
    } catch (error) {
      console.error(`Error during ${isSelected ? 'deselect' : 'select'} action:`, error);
    }
  };


  // Handle event termination or reactivation
  const handleAction = async () => {
    if (!eventToConfirm || !confirmationAction) return;

    try {
      if (confirmationAction === 'terminate') {
        await axios.post(`/event/terminate/${eventToConfirm}`);
        alert('Event terminated successfully');
      } else if (confirmationAction === 'reactivate') {
        await axios.post(`/event/reactivate/${eventToConfirm}`);
        alert('Event reactivated successfully');
      }

      // Refresh events list after action
      const response = await axios.get('/event/list');
      setEvents(response.data);

      // Close the confirmation dialog
      setConfirmationDialogOpen(false);
      setEventToConfirm(null);
      setConfirmationAction(null);
    } catch (error) {
      console.error(`Error during ${confirmationAction} action:`, error);
    }
  };

  // Open confirmation dialog
  const openConfirmationDialog = (eventId, action) => {
    setEventToConfirm(eventId);
    setConfirmationAction(action);
    setConfirmationDialogOpen(true);
  };

  return (
    <div>
      <h1>Event Management</h1>
      {/* Button to open the Create Event Dialog */}
      <Button
        variant="contained"
        onClick={() => {
          setSelectedEvent(null); // Clear selectedEvent for a new event
          setCreateDialogOpen(true); // Open the dialog
        }}
      >
        Create Event
      </Button>

      {/* Render the CreateEventDialog */}
      <CreateEventDialog
        open={createDialogOpen}
        onClose={handleCloseCreateDialog}
        onSuccess={handleEventCreated}
        existingEvent={selectedEvent}
      />

      {/* Active Events */}
      <h3>Active Events</h3>
      <List>
        {events.activeEvents.map((event) => (
          <ListItem key={event._id}>
            <ListItemText
              primary={`${event.name} - ${event.location}`}
              secondary={`From: ${new Date(event.fromDate).toLocaleString()} To: ${new Date(event.toDate).toLocaleString()}`}
            />
            <Button
              variant="outlined"
              startIcon={<VisibilityIcon />} // Adds the icon to the left of the text
              onClick={() => handleViewEvent(event)}
            >
              View Details
            </Button>
            <Button variant="outlined" onClick={() => openConfirmationDialog(event._id, 'terminate')}>
              Terminate
            </Button>
            <Button variant="outlined" onClick={() => handleViewUsers(event)}>
              Users
            </Button>
            <Button
              variant="outlined"
              startIcon={<EditOutlinedIcon />}
              onClick={() => handleOpenEditDialog(event)}
            >
              Edit
            </Button>
          </ListItem>
        ))}
      </List>

      {/* Expired Events */}
      <h3>Expired Events</h3>
      <List>
        {events.expiredEvents.map((event) => (
          <ListItem key={event._id}>
            <ListItemText
              primary={`${event.name} - ${event.location}`}
              secondary={`From: ${new Date(event.fromDate).toLocaleString()} To: ${new Date(event.toDate).toLocaleString()}`}
            />
            <IconButton onClick={() => handleViewEvent(event)}>
              <VisibilityIcon />
            </IconButton>
            <Button variant="contained" onClick={() => handleViewUsers(event)}>
              Users
            </Button>
          </ListItem>
        ))}
      </List>

      {/* Terminated Events */}
      <h3>Terminated Events</h3>
      <List>
        {events.terminatedEvents.map((event) => (
          <ListItem key={event._id}>
            <ListItemText
              primary={`${event.name} - ${event.location}`}
              secondary={`From: ${new Date(event.fromDate).toLocaleString()} To: ${new Date(event.toDate).toLocaleString()}`}
            />
            <Button
              variant="outlined"
              startIcon={<VisibilityIcon />} // Adds the icon to the left of the text
              onClick={() => handleViewEvent(event)}
            >
              View Details
            </Button>
            <Button
              variant="contained"
              onClick={() => openConfirmationDialog(event._id, 'reactivate')}
            >
              Reactivate
            </Button>
            <Button variant="outlined" onClick={() => handleViewUsers(event)}>
              Users
            </Button>
          </ListItem>
        ))}
      </List>

      {/* View Event Details Dialog */}
      <Dialog open={viewEventDialog} onClose={() => setViewEventDialog(false)} maxWidth="md">
        <DialogTitle>{selectedEvent?.name}</DialogTitle>
        <DialogContent>
          {selectedEvent?.criteria?.referral?.length > 0 && (
            <div>
              <h4>Eligible Referral Programs:</h4>
              {selectedEvent.criteria.referral.map((referral, index) => (
                <Accordion key={index}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}
                  >
                    <Typography>{referral.eventName} - {referral.eventEpisode}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <strong>From:</strong> {new Date(referral.fromDate).toLocaleString()} <br />
                      <strong>To:</strong> {new Date(referral.toDate).toLocaleString()} <br />
                      <strong>Token:</strong> {referral.token} <br />
                      <strong>Creator:</strong> {referral.creatorId} <br />
                      <strong>Terminated:</strong> {referral.terminated ? 'Yes' : 'No'}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setViewEventDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* View Event Users Dialog */}
      <DialogContent>
        <Button
          variant="contained"
          onClick={handleDownloadExcel}
          disabled={eventUsers.length === 0}
        >
          Download Excel
        </Button>
        {loading ? (
          <p>Loading...</p>
        ) : eventUsers.length === 0 ? (
          <p>No users have opted in for this event.</p>
        ) : (
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th style={{ border: '1px solid white', padding: '8px' }}>User Name</th>
                <th style={{ border: '1px solid white', padding: '8px' }}>Public Profile</th>
                <th style={{ border: '1px solid white', padding: '8px' }}>Idea</th>
                <th style={{ border: '1px solid white', padding: '8px' }}>Uploaded Links</th>
                <th style={{ border: '1px solid white', padding: '8px' }}>Created At</th>
                <th style={{ border: '1px solid white', padding: '8px' }}>Updated At</th>
                <th style={{ border: '1px solid white', padding: '8px' }}>Status</th>
              </tr>
            </thead>
            <tbody>
              {eventUsers.map((user) => (
                // Check if userId and userId.name exist
                <tr key={user.userId?._id || 'unknown'}>
                  <td style={{ border: '1px solid white', padding: '8px' }}>
                    {user.userId?.name || 'Unknown User'}
                  </td>
                  <td style={{ border: '1px solid white', padding: '8px' }}>
                    {user.userId?.pubProfileLink ? (
                      <a
                        href={`http://justwrite.soozh.app/p/${user.userId.pubProfileLink}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Public Profile
                      </a>
                    ) : (
                      'No Profile Available'
                    )}
                  </td>
                  <td style={{ border: '1px solid white', padding: '8px', maxWidth: '500px' }}>
                    {user.idea || 'No Idea Submitted'}
                  </td>
                  <td style={{ border: '1px solid white', padding: '8px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '300px' }}>
                    {user.uploads?.length > 0 ? (
                      user.uploads.map((link, index) => (
                        <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                          <Typography
                            style={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              maxWidth: '150px',
                            }}
                            title={link}
                          >
                            {link}
                          </Typography>
                          <IconButton
                            component="a"
                            href={link}
                            target="_blank"
                            rel="noopener noreferrer"
                            size="small"
                            style={{ marginLeft: '8px' }}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </div>
                      ))
                    ) : (
                      'No Links Uploaded'
                    )}
                  </td>
                  <td style={{ border: '1px solid white', padding: '8px' }}>
                    {new Date(user.createdAt).toLocaleString() || 'N/A'}
                  </td>
                  <td style={{ border: '1px solid white', padding: '8px' }}>
                    {new Date(user.updatedAt).toLocaleString() || 'N/A'}
                  </td>
                  <td style={{ border: '1px solid white', padding: '8px', maxWidth: '500px' }}>
                    <select
                      value={user.selectionstatus || 'submitted'}
                      onChange={(e) => handleStatusChange(user._id, e.target.value)}
                      style={{ padding: '4px', width: '100%', color: 'black' }}
                    >
                      <option value="submitted">Submitted</option>
                      <option value="under_review">Under Review</option>
                      <option value="shortlisted">Shortlisted</option>
                      <option value="final">Final</option>
                      <option value="selected">Selected</option>
                      <option value="not_selected">Not Selected</option>
                    </select>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </DialogContent>

      {/* Confirmation Dialog */}
      <Dialog open={confirmationDialogOpen} onClose={() => setConfirmationDialogOpen(false)}>
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to {confirmationAction} this event?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmationDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleAction} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EventManagement;
