import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Accordion, AccordionSummary, AccordionDetails, Card, Badge, Typography, Dialog, CardMedia, CardContent, Button, IconButton, Box,
    DialogTitle, DialogContent, DialogActions, Tooltip, Snackbar, Alert, TextField
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAxios } from '../../contexts/AxiosContext';
import { useLogin } from '../../contexts/LoginContext';
import { useTheme } from "../../contexts/ThemeContext";
import EventDetailsDialog from './EventDetailsDialog'; // Import the EventDetailsDialog component
import PreviewIcon from '@mui/icons-material/Preview';
import ReactConfetti from 'react-confetti';

const UserEvents = () => {
    const { theme } = useTheme();
    const navigate = useNavigate()
    const { userId, logout, pubProfileLink } = useLogin();
    const [userEvents, setUserEvents] = useState([]);
    const [expanded, setExpanded] = useState('active');
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [showCongratsDialog, setShowCongratsDialog] = useState(false);
    const axios = useAxios()

    const [deckLink, setDeckLink] = useState('');
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewUrl, setPreviewUrl] = useState('');
    const [alert, setAlert] = useState({ open: false, message: '', severity: '' });
    const [deckLinkConfirmed, setDeckLinkConfirmed] = useState(false);

    const handlePaste = (link) => {
        setDeckLink(link);
        setAlert({ open: true, message: 'Link pasted from clipboard!', severity: 'info' });
    };

    const handlePreview = (userEvent, link) => {
        if (!link) {
            setAlert({ open: true, message: 'Please paste a valid link.', severity: 'error' });
            return;
        }

        // Try to format Canva link for preview
        const isCanvaLink = link.includes('canva.com');
        const viewLink = isCanvaLink ? link.replace('/edit', '/view') : link;

        // Check if the link can be previewed in an iframe
        setSelectedEvent(userEvent);
        setPreviewUrl(viewLink);
        setPreviewOpen(true);
    };

    const handleUpload = async () => {
        if (!deckLinkConfirmed) {
            setAlert({ open: true, message: 'You must confirm before submitting.', severity: 'error' });
            return;
        }

        if (!selectedEvent) {
            setAlert({ open: true, message: 'No event selected. Please try again.', severity: 'error' });
            return;
        }

        try {
            console.log(previewUrl)
            const response = await axios.post(`/userevents/uploads/${userId}/${selectedEvent.eventId._id}`, {
                urls: [previewUrl], // Sending an array of URLs
            });
            if (response.status === 200) {
                setAlert({ open: true, message: 'Link uploaded successfully!', severity: 'success' });
                setPreviewOpen(false);
                setDeckLink('');
                updateEventDetails({ ...selectedEvent, uploads: response.data.uploads });
            }
        } catch (error) {
            console.error('Error uploading link:', error);
            setAlert({ open: true, message: 'Failed to upload link. Please try again.', severity: 'error' });
        }
    };



    useEffect(() => {
        if (userEvents.some(event => !event.read)) {
            setShowCongratsDialog(true);
        }
    }, [userEvents]);

    useEffect(() => {
        // Fetch user events on component load
        const fetchUserEvents = async () => {
            try {
                const response = await axios.get(`/userevents/user-events/${userId}`);
                setUserEvents(response.data.userEvents);
            } catch (error) {
                console.error('Error fetching user events:', error);
            }
        };
        fetchUserEvents();
    }, [userId]);

    // Categorize events into active, expired, and archived
    const now = new Date();
    const activeEvents = userEvents.filter(event => !event.eventId.terminated && new Date(event.eventId.toDate) > now);
    const expiredEvents = userEvents.filter(event => !event.eventId.terminated && new Date(event.eventId.toDate) <= now);
    const archivedEvents = userEvents.filter(event => event.archived);

    const handleExpand = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleOpenDialog = (eventData) => {
        setSelectedEvent(eventData);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setSelectedEvent(null);
    };

    const updateEventDetails = (updatedDetails) => {
        setUserEvents((prevEvents) =>
            prevEvents.map(event =>
                event.eventId._id === updatedDetails.eventId._id
                    ? { ...event, ...updatedDetails }
                    : event
            )
        );
        setSelectedEvent((prevEvent) => ({
            ...prevEvent,
            ...updatedDetails
        }));
    };


    return (
        <div style={{ padding: '30px' }}>
            <Typography variant="h3" gutterBottom>  {/* Add gutterBottom for spacing below */}
                Welcome to Events
            </Typography>

            <Typography variant="body1" gutterBottom>  {/* Add gutterBottom for spacing below */}
                Here you find the best opportunity to showcase your talent! Do Not Miss!
            </Typography>

            <div style={{ height: '20px' }}></div> {/* Add a gap before the accordion */}
            <div height="100px"></div>
            {/* Active Events */}
            <Accordion
                expanded={expanded === 'active'}
                onChange={handleExpand('active')}
                disabled={activeEvents.length === 0} // Disable if no active events
                style={{ backgroundColor: 'black', border: '1px solid #ccc', color: '#fff' }} // Black background and outlined style
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">Active Events {activeEvents.length === 0 && ' - No Events'}</Typography> {/* Update title */}
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {activeEvents.map((userEvent, index) => (
                            <Card
                                key={index}
                                style={{
                                    width: '100%',
                                    maxWidth: '300px',
                                    margin: '10px',
                                    position: 'relative',
                                    border: userEvent.read ? '1px solid #ccc' : '2px solid #1976d2', // Use a thicker, colored border for unread cards
                                    boxShadow: userEvent.read ? 'none' : '0 0 10px rgba(25, 118, 210, 0.5)', // Add a subtle shadow for unread cards
                                }}
                            >
                                {!userEvent.read && (
                                    <Badge
                                        badgeContent="Unread"
                                        color="error"
                                        style={{ position: 'absolute', top: '20px', left: '35px' }}
                                        sx={{ '& .MuiBadge-badge': { backgroundColor: 'black', color: 'white' } }}  // Black background for the badge
                                    />
                                )}
                                {/* Existing Badge */}
                                <Badge
                                    badgeContent={userEvent.selectionstatus ? userEvent.selectionstatus : 'Status N/A'}
                                    color={userEvent.optedIn ? 'success' : 'error'}
                                    style={{ position: 'absolute', top: '20px', right: '40px' }}
                                    sx={{ '& .MuiBadge-badge': { backgroundColor: 'black', color: 'white' } }}  // Black background for the badge
                                />
                                <CardMedia
                                    component="img"
                                    height="140"
                                    image={userEvent.eventId.images?.[0] || 'https://via.placeholder.com/400x300.png?text=No+Image'}
                                    alt={userEvent.eventId.name}
                                />
                                <CardContent>
                                    <Typography variant="h6">{userEvent.eventId.name}</Typography>
                                    <Typography variant="body2">{userEvent.eventId.location}</Typography>
                                    <Typography variant="body2">From: {new Date(userEvent.eventId.fromDate).toLocaleString()}</Typography>
                                    <Typography variant="body2">To: {new Date(userEvent.eventId.toDate).toLocaleString()}</Typography>
                                    <Typography variant="body2" gutterBottom>
                                        <strong>Submitted Idea:</strong> {userEvent.idea}
                                    </Typography>
                                    {userEvent.uploads.length > 0 ? (
                                        <Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 1,
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        maxWidth: '200px', // Restricts the width for scrolling
                                                        display: 'block',  // Ensures the box behaves as a block
                                                        cursor: 'pointer',
                                                    }}
                                                    title={userEvent.uploads[0]} // Shows full URL on hover
                                                >
                                                    <Typography variant="body2">
                                                        <strong>Uploaded Link:</strong> {userEvent.uploads[0]}
                                                    </Typography>
                                                </Box>
                                                <IconButton
                                                    component="a"
                                                    href={userEvent.uploads[0]}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    size="small"
                                                    color="primary"
                                                >
                                                    <Tooltip title="View Link">
                                                        <PreviewIcon fontSize="small" /> {/* Replace with desired icon */}
                                                    </Tooltip>
                                                </IconButton>
                                            </Box>
                                            <Typography variant="body2" color="success.main">Already uploaded. Thank you!</Typography>
                                        </Box>
                                    ) : (
                                        <Box>
                                            <TextField
                                                fullWidth
                                                label="Paste your deck link"
                                                variant="outlined"
                                                value={deckLink}
                                                onChange={(e) => setDeckLink(e.target.value)}
                                                onPaste={(e) => handlePaste(e.clipboardData.getData('Text'))}
                                                helperText="Paste a public link to your deck"
                                                sx={{ marginBottom: 2 }}
                                            />
                                            <Button variant="contained" color="primary" onClick={() => handlePreview(userEvent, deckLink)}>
                                                Preview & Submit
                                            </Button>
                                        </Box>
                                    )}
                                    <Button
                                        variant="outlined"
                                        onClick={() => handleOpenDialog(userEvent)}
                                        style={{ marginTop: '10px' }}
                                    >
                                        Open Details
                                    </Button>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                </AccordionDetails>
            </Accordion>

            {/* Expired Events */}
            <Accordion
                expanded={expanded === 'expired'}
                onChange={handleExpand('expired')}
                disabled={expiredEvents.length === 0} // Disable if no active events
                style={{ backgroundColor: 'black', border: '1px solid #ccc', color: '#fff' }} // Black background and outlined style
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">Expired Events {expiredEvents.length === 0 && ' - No Events'}</Typography> {/* Update title */}
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {expiredEvents.map((userEvent, index) => (
                            <Card
                                key={index}
                                style={{
                                    width: '100%',  // Make the card take up full width on smaller screens
                                    maxWidth: '300px',  // Set a maximum width for larger screens
                                    margin: '10px',
                                    position: 'relative'
                                }}
                            >
                                <Badge badgeContent="Expired" color="error" style={{ position: 'absolute', top: '10px', right: '10px' }} />
                                <CardMedia
                                    component="img"
                                    height="140"
                                    image={userEvent.eventId.images?.[0] || 'https://via.placeholder.com/400x300.png?text=No+Image'}
                                    alt={userEvent.eventId.name}
                                />
                                <CardContent>
                                    <Typography variant="h6">{userEvent.eventId.name}</Typography>
                                    <Typography variant="body2">{userEvent.eventId.location}</Typography>
                                    <Typography variant="body2">From: {new Date(userEvent.eventId.fromDate).toLocaleString()}</Typography>
                                    <Typography variant="body2">To: {new Date(userEvent.eventId.toDate).toLocaleString()}</Typography>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                </AccordionDetails>
            </Accordion>

            {/* Archived Events */}
            <Accordion
                expanded={expanded === 'archived'}
                onChange={handleExpand('archived')}
                disabled={archivedEvents.length === 0} // Disable if no active events
                style={{ backgroundColor: 'black', border: '1px solid #ccc', color: '#fff' }} // Black background and outlined style
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">Archived Events {archivedEvents.length === 0 && ' - No Events'}</Typography> {/* Update title */}
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {archivedEvents.map((userEvent, index) => (
                            <Card
                                key={index}
                                style={{
                                    width: '100%',  // Make the card take up full width on smaller screens
                                    maxWidth: '300px',  // Set a maximum width for larger screens
                                    margin: '10px',
                                    position: 'relative'
                                }}
                            >
                                <Badge badgeContent="Archived" color="default" style={{ position: 'absolute', top: '10px', right: '10px' }} />
                                <CardMedia
                                    component="img"
                                    height="140"
                                    image={userEvent.eventId.images?.[0] || 'https://via.placeholder.com/400x300.png?text=No+Image'}
                                    alt={userEvent.eventId.name}
                                />
                                <CardContent>
                                    <Typography variant="h6">{userEvent.eventId.name}</Typography>
                                    <Typography variant="body2">{userEvent.eventId.location}</Typography>
                                    <Typography variant="body2">From: {new Date(userEvent.eventId.fromDate).toLocaleString()}</Typography>
                                    <Typography variant="body2">To: {new Date(userEvent.eventId.toDate).toLocaleString()}</Typography>
                                    {/* Button to open event details dialog */}

                                </CardContent>
                            </Card>
                        ))}
                    </div>
                </AccordionDetails>
            </Accordion>

            {/* Render the EventDetailsDialog */}
            {selectedEvent && (
                <EventDetailsDialog
                    open={dialogOpen}
                    onClose={handleCloseDialog}
                    eventDetails={selectedEvent}
                    userId={userId} // Replace with actual user ID
                    updateEventDetails={updateEventDetails} // Pass the update function
                />
            )}

            <Dialog
                open={showCongratsDialog}
                onClose={() => setShowCongratsDialog(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>🎉 Congratulations! 🎉</DialogTitle>
                <DialogContent>
                    <Typography variant="h6">
                        You have a new event that you haven't checked out yet!
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowCongratsDialog(false)} color="primary" variant="contained">
                        Awesome!
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Confetti Animation */}
            {showCongratsDialog && <ReactConfetti width={window.innerWidth} height={window.innerHeight} />}

            <Dialog open={previewOpen} onClose={() => setPreviewOpen(false)}>
                <DialogTitle>Preview Link</DialogTitle>
                <DialogContent>
                    <Box>
                        {previewUrl.includes('canva.com') ? (
                            <iframe
                                src={previewUrl}
                                style={{ width: '100%', height: '400px', border: 'none' }}
                                title="Link Preview"
                                allowFullScreen
                                onError={() => {
                                    setAlert({
                                        open: true,
                                        message: 'Preview not available. Please open in a new tab.',
                                        severity: 'warning',
                                    });
                                }}
                            ></iframe>
                        ) : (
                            <Typography color="error">
                                Unable to preview this link.
                            </Typography>
                        )}
                        <Button
                            variant="outlined"
                            sx={{ marginTop: 2 }}
                        >
                            <a href={previewUrl} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                                Open in a new tab
                            </a>
                        </Button>

                    </Box>
                    <Box mt={2}>
                        <label>
                            <input
                                type="checkbox"
                                onChange={(e) => setDeckLinkConfirmed(e.target.checked)}
                            />{' '}
                            I confirm this is the correct link and understand it cannot be changed once submitted.
                        </label>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setPreviewOpen(false)} color="secondary">Cancel</Button>
                    <Button
                        onClick={handleUpload}
                        color="primary"
                        disabled={!deckLinkConfirmed}
                    >
                        Submit Link
                    </Button>
                </DialogActions>
            </Dialog>


            <Snackbar
                open={alert.open}
                autoHideDuration={3000}
                onClose={() => setAlert({ ...alert, open: false })}
            >
                <Alert onClose={() => setAlert({ ...alert, open: false })} severity={alert.severity}>
                    {alert.message}
                </Alert>
            </Snackbar>

        </div>
    );
};

export default UserEvents;
